<template>
  <div class="seopop">
    <div class="site-section bg-light">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" style="margin-top: 60px;">
            <h2 class="section-title mb-3 text-normal-blue">Advisory Consulting</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-5" data-aos="fade-right">
            <br><br>
            <img src="images/undraw_remote_meeting_cbfk.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-7 ml-auto pl-lg-7">
            <p class="mb-4 text-black">We are a team of exceptional business consultants with broad and deep experience in Strategic Management,  Information Technology,  Program and  Change Management</p>
          
            <h2 class="text-normal-blue mb-4 h4 font-weight-bold">What make Clikry Advisory consultants different:</h2>
             <ul class="ul-check mb-5 list-unstyled success text-black">
              <li>We are passionate about helping our clients operationalize their strategies and create sustainable change.</li>
              <li>We have diverse industry knowledge and we make change stick by improving all three critical operations areas: process, technology and organization design.</li>
              <li>We work collaboratively on behalf of our clients with their vendors and partners. This creates a sense of ownership and involves the client in the decision making and adopt to change much easier with little or no disruption to their existing business.</li>
              <li>Although we have proven methodologies, our approach is always tailored to best meet our clients’ needs. This customization ensures that we can achieve their goals in the shortest amount of time and also be agile in accommodating innovative ideas to test and measure the results.</li>
              <li>Our consultants listen to client and work closely to understand and become experts on our clients’ work, which enables them to suggest radical improvements, alternatives and enhancements.</li>
              <li>We work quickly to help our clients achieve milestones and measure results in 4 to 8 weeks increments. These milestones reinforce buy-in and fund future improvements.</li>
              <li>Clikry advisory services and solutions are applicable across a variety of industries.</li>
              <li>We quickly achieve measurable results by engaging our clients to ensure the sponsorship and success of each change initiative</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Advisory Consulting",
  components: {
    Footer
  }
};
</script>